import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import * as styles from "./Partners.module.scss"
import Seo from "../../components/Seo/seo"
import { ContentfulPartnersPageQuery } from "../../../types/gatsby-graphql"

const SignUp = () => {
  const data: ContentfulPartnersPageQuery = useStaticQuery(graphql`
    query ContentfulPartnersPage {
      contentfulPartnersPage {
        title
        description {
          description
        }
        partners {
          title
          logo {
            file {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Partners" />
      <div className={styles.partnerContainer}>
        <div className={styles.partnersHeading}>
          <h1>{data?.contentfulPartnersPage?.title}</h1>
          <span>{data?.contentfulPartnersPage?.description?.description}</span>
        </div>
        <div className={styles.partnersImages}>
          {data?.contentfulPartnersPage?.partners?.reverse().map(partner => {
            return <img src={partner?.logo?.file?.url!} alt={partner?.title!} />
          })}
        </div>
      </div>
    </>
  )
}

export default SignUp
